<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Admin Users</h4>
              </CCol>
              <CCol md="3">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
              <CCol md="2">
                   <b-button variant="primary" to="/useradmin/create" >New Admin</b-button>                    
              </CCol>             
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
            </div>

          <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">


          <template v-slot:cell(groups)="{ item }"> 
                {{ item.groups | passOnlyString }}
          </template>

          <template v-slot:cell(actions)="{ item }">            

            <b-dropdown variant="info" text="Actions" size="md">             
              <!--router-link to="#" tag="b-dropdown-item">
                   <span v-on:click="ChangeToBlock(item.userName)">Block</span>
              </router-link>
              <router-link to="#" tag="b-dropdown-item">
                   <span v-on:click="ChangeToActive(item.userName)">Activate</span>
              </router-link--> 

              <router-link to="#" exact v-on:click.native="ChangeToBlock(item.userName)" tag="b-dropdown-item" > Block </router-link>
              <router-link to="#" exact v-on:click.native="ChangeToActive(item.userName)" tag="b-dropdown-item" > Activate </router-link>


              <router-link :to='"/useradmin/edit/" + item.userName' tag="b-dropdown-item" > Edit </router-link>
              <router-link :to='"/useradmin/replacepasswd/" + item.userName' tag="b-dropdown-item" > Change Password </router-link>
            </b-dropdown> 

          </template>

        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>

          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import AdminService from '@/api/AdminService.js';
import moment from 'moment';

 export default {
    name: "users",
    data() { var date = new Date();
      return {        
        items:[],             
        fields: [   
          {
            name : 'userName',
            key: 'userName',
            label : 'User Name',
            sortable: true
          },  
          {
            key: 'email',
            label : 'Email',
            sortable: true
          },   
          {
            key: 'firstName',
            label : 'Fist Name',
            sortable: true
          },         
          {
            key: 'lastName',
            label : 'Last Name',
            sortable: true
          },        
          {
            key: 'phoneNumber',
            label : 'Phone Number',
            sortable: true
          },
          {
            key: 'groups',
            label : 'Groups',
            sortable: true
          },
          {
            key: 'status',
            label : 'Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }                       
                    
          ],
        optGroup:[],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        spinner:false,
        seen:false,
        msg :'',
        color:'' 
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        passOnlyString(value){
           if(!value) return ''
           value = value.toString()
           return value.replace(/[^a-zA-Z ]/g, "")
        },       

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


      },

    created () {

      this.isSuperAdmin();
      this.getUserGroupList();
      setTimeout( () =>  this.showAllAdminList() , 5000); 
      // var init   = [];  let params = {};
      // init.push('admin'); init.push('super_admin'); params['groupList'] = init; 
      // var result = JSON.stringify(params);
      // this.getUserAdminList(result);
    },
    methods: { 

      showAllAdminList(){
          let params = {}; params['groupList'] = this.optGroup; 
          var result = JSON.stringify(params);
          this.getUserAdminList(result); 
      },

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      ChangeToBlock(userName){

          if(localStorage.getItem('sa')==="true"){  
              let params = {};
              params['status']   = "BLOCKED";
              params['userName'] = userName;
              this.changeStsAdmUser(params); 
          } else { 
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
          }

      },

      ChangeToActive(userName){

          if(localStorage.getItem('sa')==="true"){  
              let params = {};
              params['status']   = "ACTIVE";
              params['userName'] = userName;
              this.changeStsAdmUser(params); 
          } else { 
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
          }

      },

      changeStsAdmUser: function(params) {
        AdminService.changeStsAdmUser(params).then(resp => { 

            if(resp.message==="SUCCESS"){                  
              //this.msg   = "Update Status Admin Success!"; 
              //this.color = 'success'; this.seen = true;

              alert("Update Status Admin Success!");
              // var init   = [];  let params = {};
              // init.push('admin'); init.push('super_admin'); params['groupList'] = init; 
              // var result = JSON.stringify(params);
              // this.getUserAdminList(result);
              this.getUserGroupList();
              setTimeout( () =>  this.showAllAdminList() , 5000); 

            } else {
              this.msg   =  !resp.data.message ? "Update Status Admin Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            } 
            setTimeout( () =>  this.seen=false , 5000); 
           
        }, error => {
          this.loading = false;
        });
      },

      getUserGroupList: function() { 
          AdminService.getUserGroupList().then(resp => {  
            this.spinner = true;           
            var src = resp.payload; var init =[];
            for (var key in src) {  
              var name = src[key].name; //console.log(name);
              if(name!=="user") { init.push(name); }
            } 
            this.optGroup = init;

           }, error => {
            console.log('error');    
          });
      },  
      
      getUserAdminList: function(params) {
        AdminService.getUserAdminList(params).then(resp => {  //console.log(resp.payload);           
           var src = resp.payload; var init =[]; 
            for (var key in src) {  
              var name = src[key].userName; 
              if(name !=="admin") { init.push(src[key]); }
            } 
            this.items = init;
            this.spinner = false;
        }, error => {
          this.loading = false;
        });
      }

    }    
 }; 

</script>
<style>
    .table{ hoverflow-y:scroll; }
</style>